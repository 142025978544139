import React from 'react';
import styled from 'styled-components';
import LaunchButton from './LaunchButton';

const Container = styled.div`
    background-color: #73D2DE;
    background-image: url('/images/dots-yellow.png');
    background-repeat-y: no-repeat;
    background-position-y: bottom;
    min-height: 500px;
    position: relative;
    text-align: center;
`;

function GoToAppSection(props: React.HTMLProps<HTMLDivElement>) {
    const { className, ...rest } = props
    const classNames = ['flex', 'justify-center', 'items-center', props.className].filter(Boolean).join(' ');
    return (
        <Container {...rest} className={classNames}>
            <div>
                <LaunchButton backgroundColor='#4DACB8' href='https://app.accumulator.finance/' target='_blank'>Go to Accumulator</LaunchButton>
            </div>
        </Container >
    )
}

export default GoToAppSection;