import React from 'react';
import styled from 'styled-components';
import ButtonLink from '../components/ButtonLink';

const Container = styled.div`
  height: 130px;
`;

const Nut = styled.img`
  width: 45px;
  height: 45px;
`;
Nut.defaultProps = {
  src: '/images/nut.svg',
};

function Header() {
  return (
    <Container className="flex items-center justify-between md:justify-between px-12">
      <Nut />
      <ButtonLink href="https://app.accumulator.finance/" target="_blank">
        Enter the dApp
      </ButtonLink>
    </Container>
  );
}

export default Header;
